.gauge-search-container {
  margin: 20px;
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;
}

.gauge-search-container h1 {
  margin-bottom: 10px;
}

.search-instructions {
  margin-bottom: 20px;
  color: #666;
}

.search-form {
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
}

.search-input {
  flex: 1;
  padding: 10px 15px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
  min-width: 0; /* Prevent flex item from overflowing */
}

.search-button {
  padding: 10px 20px;
  background-color: #4caf50;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  white-space: nowrap;
}

.search-button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.error {
  color: #f44336;
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid #f44336;
  border-radius: 5px;
  background-color: #ffebee;
}

.loading {
  padding: 20px;
  text-align: center;
  color: #666;
}

.gauge-details {
  margin-top: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 20px;
  background-color: #fff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.gauge-details h2 {
  margin-top: 0;
  margin-bottom: 20px;
  border-bottom: 1px solid #ddd;
  padding-bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.details-grid {
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;
}

@media (min-width: 768px) {
  .details-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

.detail-card {
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  padding: 15px;
  background-color: #fafafa;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);
}

.detail-card h3 {
  margin-top: 0;
  margin-bottom: 15px;
  padding-bottom: 10px;
  border-bottom: 1px solid #eee;
  color: #333;
  font-size: 18px;
}

.detail-item {
  display: flex;
  padding-bottom: 10px;
  margin-bottom: 10px;
  border-bottom: 1px solid #eee;
}

.detail-item:last-child {
  border-bottom: none;
  margin-bottom: 0;
}

.detail-item .label {
  font-weight: bold;
  width: 140px;
  flex-shrink: 0;
  color: #555;
}

.detail-item .value {
  word-break: break-all;
  font-family: monospace;
}

/* Styling for boosts section */
.boost-providers {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
  gap: 15px;
}

.boost-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  border: 1px solid #e0e0e0;
  border-radius: 6px;
  background-color: #fff;
  text-align: center;
}

.provider-name {
  font-weight: bold;
  text-transform: capitalize;
  margin-bottom: 10px;
  color: #333;
  font-size: 16px;
}

.boost-value {
  font-size: 22px;
  font-weight: bold;
  color: white;
  padding: 8px 15px;
  border-radius: 20px;
  margin: 0 auto;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 80px;
}

/* Verification badge */
.verification-badge {
  display: inline-block;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 14px;
  font-weight: bold;
}

.valid {
  background-color: #e8f5e9;
  color: #4caf50;
  border: 1px solid #a5d6a7;
}

.invalid {
  background-color: #ffebee;
  color: #f44336;
  border: 1px solid #ef9a9a;
}

.valid-text {
  color: #4caf50;
}

.invalid-text {
  color: #f44336;
}

/* Raw data toggle */
.raw-data-toggle {
  margin-top: 20px;
  border-top: 1px solid #ddd;
  padding-top: 15px;
}

.raw-data-toggle summary {
  cursor: pointer;
  font-weight: bold;
  color: #555;
  padding: 8px;
  background-color: #f5f5f5;
  border-radius: 4px;
  user-select: none;
}

.raw-data-toggle summary:hover {
  background-color: #eeeeee;
}

.json-data {
  background-color: #f5f5f5;
  padding: 15px;
  border-radius: 5px;
  overflow-x: auto;
  font-family: monospace;
  font-size: 14px;
  margin-top: 15px;
  border: 1px solid #ddd;
  max-height: 300px;
  overflow-y: auto;
}

.loading::after {
  content: '...';
  animation: dots 1.5s infinite;
}

@keyframes dots {
  0%,
  20% {
    content: '.';
  }
  40% {
    content: '..';
  }
  60%,
  100% {
    content: '...';
  }
}

.retry-button {
  margin-top: 10px;
  padding: 8px 16px;
  background-color: #d32f2f;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-weight: bold;
}

.retry-button:hover {
  background-color: #b71c1c;
}

.gauge-info {
  margin-top: 10px;
  text-align: center;
  color: #666;
  font-style: italic;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.refresh-button {
  background-color: #f0f0f0;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 5px 10px;
  font-size: 12px;
  cursor: pointer;
  margin-top: 5px;
  transition: background-color 0.2s;
}

.refresh-button:hover {
  background-color: #e0e0e0;
}

.refresh-button i {
  margin-right: 5px;
}

.button-group {
  display: flex;
  gap: 10px;
  justify-content: center;
  margin-top: 10px;
}

.diagnostic-button {
  background-color: #e8f4f8;
  border: 1px solid #93c5fd;
  border-radius: 4px;
  padding: 5px 10px;
  font-size: 12px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.diagnostic-button:hover {
  background-color: #dbeafe;
}

.diagnostic-button i {
  margin-right: 5px;
  color: #3b82f6;
}

.no-results {
  padding: 15px;
  text-align: center;
  color: #666;
  font-style: italic;
}

/* Gauge voting link */
.gauge-voting-link {
  margin-top: 30px;
  padding-top: 20px;
  border-top: 1px solid #eee;
  text-align: center;
}

.gauge-voting-link a {
  display: inline-block;
  padding: 10px 20px;
  background-color: #f5f5f5;
  color: #333;
  text-decoration: none;
  border-radius: 5px;
  font-weight: bold;
  transition: background-color 0.2s;
}

.gauge-voting-link a:hover {
  background-color: #e0e0e0;
}

.gauge-voting-link i {
  margin-right: 8px;
}
